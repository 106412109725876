import LazyImage from "Components/Elements/LazyImage";
import Typography from "Components/Elements/Typography";
import configHelper from "common/helpers/config";
import Dropdown from "./Dropdown";
import classes from "./classes.module.scss";
import { faqQuestions } from "./questions";

export default function FAQ() {
	const backgroundImage = configHelper.getSrc("assets/images/landingPage/faq/background.webp");
	const questionMarkIcon = configHelper.getSrc("assets/images/landingPage/faq/question-mark.svg");

	return (
		<div className={classes["root"]} style={{ backgroundImage: `url(${backgroundImage})` }}>
			<div className={classes["content"]}>
				<div className={classes["dropdown-container"]}>
					<Dropdown questions={faqQuestions} />
				</div>
				<div className={classes["container"]}>
					<LazyImage src={questionMarkIcon} className={classes["question-mark-icon"]} alt={"question mark"}/>
					<div className={classes["wrapper"]}>
						<Typography className={classes["title"]} type="h1">
							Frequently
							<br /> Asked
							<br /> Questions
						</Typography>
						<Typography className={classes["description"]} type="p">
							Curious about Raresips? Ask us anything about our brand and products, and get factual responses.
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
}
