import { useSpring } from "@react-spring/web";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import LazyImage, { AnimatedLazyImage } from "Components/Elements/LazyImage";
import Typography from "Components/Elements/Typography";
import configHelper from "common/helpers/config";
import classes from "./classes.module.scss";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import { Link } from "react-router-dom";

export default function Welcome(): JSX.Element {
	const angels_envyImg = configHelper.getSrc("assets/images/landingPage/welcome/angels_envy.webp");
	const caviarImg = configHelper.getSrc("assets/images/landingPage/welcome/caviar.webp");
	const whistlepigImg = configHelper.getSrc("assets/images/landingPage/welcome/whistlepig_thebosshog.webp");
	const background = configHelper.getSrc("assets/images/landingPage/welcome/background.webp");
	const i18nBaseKey = "pages.home.landing_pages.home";
	const welcomeBackgroundVideo = configHelper.getSrc("assets/videos/landingPage/welcome/background.mp4");

	const angelsEnvyAnimationStyles = useSpring({
		from: { rotate: "20.624deg", transform: "translate3d(0, 0, 0) scale(1)" },
		to: [
			{ rotate: "31.951deg", transform: "translate3d(0, 0, 0) scale(1.15)" },
			{ rotate: "20.624deg", transform: "translate3d(0, 0, 0) scale(1)" },
		],
		loop: true,
		config: { mass: 10, tension: 100, friction: 15 },
		duration: 9146,
	});

	return (
		<div className={classes["root"]} style={{ backgroundImage: `url(${background})` }}>
			<div className={classes["content"]}>
				<div className={classes["container"]}>
					<AnimatedLazyImage
						alt={"Angels Envy"}
						src={angels_envyImg}
						className={classes["angels-envy-bottle-img"]}
						style={angelsEnvyAnimationStyles}
					/>
					<LazyImage src={caviarImg} className={classes["caviar-img"]} alt={"Caviar bottle"}/>
					<LazyImage src={whistlepigImg} className={classes["whistlepig-bottle-blurred-img"]} alt={"whistlepig bottle"}/>
					<LazyImage src={whistlepigImg} className={classes["whistlepig-bottle-img"]} alt={"whistlepig bottle"} />

					<Typography className={classes["title"]} type="h1">
						Raresips
					</Typography>
					<div className={classes["text-container"]}>
						<Typography type="h2" className={classes["subtitle"]}>
							<I18n map={`${i18nBaseKey}.subtitle`} />
						</Typography>
						<Link to={IsModuleEnabled.get().pages.Market.props.path}>
							<Button
								variant={EButtonVariant.CONTAINED}
								color={EButtonColor.PRIMARY}
								size={EButtonSize.LARGE}
								className={classes["get-started-button"]}>
								<I18n map={`${i18nBaseKey}.get_started`} />
							</Button>
						</Link>
					</div>
				</div>
				<video playsInline autoPlay muted loop>
					<source src={welcomeBackgroundVideo} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
		</div>
	);
}
