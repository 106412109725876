import AppTokenSupport from "Api/Back/AppTokenSupport";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import EventService from "Services/EventEmitter";
import TokenHelper from "common/helpers/token";
import ProductionTokenSupports from "Configs/TokenSupports/production.json";
import PreprodTestnetTokenSupports from "Configs/TokenSupports/preprod-testnet.json";
import PreprodMainnetTokenSupports from "Configs/TokenSupports/preprod-mainnet.json";
import StagingTokenSupports from "Configs/TokenSupports/staging.json";
import DevelopmentTokenSupports from "Configs/TokenSupports/development.json";

const AppTokenSupports = {
	development: DevelopmentTokenSupports,
	staging: StagingTokenSupports,
	"preprod-testnet": PreprodTestnetTokenSupports,
	"preprod-mainnet": PreprodMainnetTokenSupports,
	production: ProductionTokenSupports,
};
export default class TokenSupports {
	private static instance: TokenSupports;
	private readonly event = new EventService();
	private readonly appTokenSupport = AppTokenSupport.getInstance();

	private tokenSupports: AppTokenSupportEntity[] = [];

	private constructor() {
		TokenSupports.instance = this;
	}

	public static getInstance() {
		if (!TokenSupports.instance) return new this();
		return TokenSupports.instance;
	}

	public getTokenSupports() {
		return this.tokenSupports;
	}

	public setTokenSupports(tokenSupports: AppTokenSupportEntity[]) {
		this.tokenSupports = tokenSupports;
		this.event.emit("change", tokenSupports);
	}

	public getTokenByName(name: string): AppTokenSupportEntity | undefined {
		return this.tokenSupports.find((token) => token.name === name);
	}

	public getTokenByAddress(address: string): AppTokenSupportEntity | undefined {
		return this.tokenSupports.find((token) => token.address === address);
	}

	public async getNativeToken(chainId?: number): Promise<AppTokenSupportEntity | undefined> {
		if (!chainId) return;
		return this.tokenSupports.find(
			(token) =>
				(token.name === TokenHelper.chainIdToNativeTokenName.get(chainId) ||
					token.symbol === TokenHelper.chainIdToNativeTokenName.get(chainId)) &&
				token.protocol === "NATIVE",
		);
	}

	public async fetchTokenSupports(init?: boolean) {
		if (init) {
			const tokenSupports = AppTokenSupports[
				(process.env["REACT_APP_ENV_NAME"] as keyof typeof AppTokenSupports) || "development"
			] as AppTokenSupportEntity[];
			this.setTokenSupports(tokenSupports);

			setTimeout(async () => {
				const tokenSupports = await this.appTokenSupport.getTokenSupports();
				this.setTokenSupports(tokenSupports);
			}, 30000);
			return;
		}
		const tokenSupports = await this.appTokenSupport.getTokenSupports();
		this.setTokenSupports(tokenSupports);
	}

	public onChange(callback: (tokenSupports: AppTokenSupportEntity[]) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}
}
