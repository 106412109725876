import { AnimatedLazyImage } from "Components/Elements/LazyImage";
import Typography, { AnimatedTypography } from "Components/Elements/Typography";
import configHelper from "common/helpers/config";
import { useEffect, useRef, useState } from "react";
import classes from "./classes.module.scss";

import { Controller, animated } from "@react-spring/web";
import WindowStore from "Stores/Window";

const animationSpringConfig = {
	mass: 1,
	tension: 44.44,
	friction: 10,
};

const widthAnimationEnabled = 1023;

export default function PhysicalDigital(): JSX.Element {
	const [enableAnimation, setEnableAnimation] = useState(window.innerWidth > widthAnimationEnabled);

	useEffect(() => {
		const remove = WindowStore.getInstance().onResize(() => {
			setEnableAnimation(window.innerWidth > widthAnimationEnabled);
		});

		return () => remove();
	}, []);

	useEffect(() => {
		const onScrollMobile = () => {
			if (window.innerWidth <= widthAnimationEnabled) {
				const physicalTitle = document.getElementById("physical-title");
				const digitalTitle = document.getElementById("digital-title");
				const physicalSubtitle = document.getElementById("physical-subtitle");
				const digitalSubtitle = document.getElementById("digital-subtitle");
				const physicalBottle = document.getElementById("physical-bottle");
				const digitalBottle = document.getElementById("digital-bottle");

				const elements = [physicalTitle, digitalTitle, physicalSubtitle, digitalSubtitle, physicalBottle, digitalBottle];
				elements.forEach((element) => {
					if (element) {
						const top = element.getBoundingClientRect().top;
						const bottom = element.getBoundingClientRect().bottom;

						if (top < window.innerHeight && bottom > 0) {
							element.classList.add(classes["visible-element"]!);
						} else {
							element.classList.remove(classes["visible-element"]!);
						}
					}
				});
			}
		};

		window.addEventListener("scroll", onScrollMobile);

		return () => window.removeEventListener("scroll", onScrollMobile);
	}, []);

	const isPhysicalHovered = useRef(false);
	const isDigitalHovered = useRef(false);

	const physicalSection = useRef<HTMLDivElement | null>(null);
	const digitalSection = useRef<HTMLDivElement | null>(null);

	const initialXPhysicalImg = useRef(physicalSection.current?.clientWidth);

	const physicalBackgroundVideo = configHelper.getSrc("assets/videos/landingPage/physicalDigital/background_1.mp4");
	const digitalBackgroundVideo = configHelper.getSrc("assets/videos/landingPage/physicalDigital/background_2.mp4");

	const whistlepig1Img = configHelper.getSrc("assets/images/landingPage/physicalDigital/whistlepig_1.webp");
	const whistlepig2Img = configHelper.getSrc("assets/images/landingPage/physicalDigital/whistlepig_2.webp");

	const physicalSectionAnimations = useRef(
		new Controller({
			width: "50%",
			config: animationSpringConfig,
		}),
	);

	const physicalTitleAnimations = useRef(new Controller({}));

	const physicalSubtitleAnimations = useRef(new Controller({}));

	const physicalBottleAnimations = useRef(new Controller({}));

	const digitalSectionAnimations = useRef(
		new Controller({
			width: "50%",
			config: animationSpringConfig,
		}),
	);

	const digitalTitleAnimations = useRef(new Controller({}));

	const digitalSubtitleAnimations = useRef(new Controller({}));

	const digitalBottleAnimations = useRef(new Controller({}));

	const launchedAnimation = () => {
		let initialScale = 1;
		let selectedScale = 1.15;
		if (window.innerWidth <= 1440) {
			initialScale = 0.8;
			selectedScale = 0.92;
		}

		if (isPhysicalHovered.current) {
			physicalSectionAnimations.current.start({
				width: "85%",
				config: animationSpringConfig,
			});

			physicalTitleAnimations.current.start({
				x: "5vw",
				y: "195px",
				fontSize: "10vw",
				rotate: 0,
				config: animationSpringConfig,
			});

			physicalSubtitleAnimations.current.start({
				opacity: 1,
				x: "5vw",
				config: animationSpringConfig,
			});

			physicalBottleAnimations.current.start({
				opacity: 1,
				x: "20%",
				right: 0,
				y: "10%",
				scale: selectedScale,
				rotate: 8.77,
				config: animationSpringConfig,
			});

			digitalSectionAnimations.current.start({
				width: "15%",
				config: animationSpringConfig,
			});

			digitalTitleAnimations.current.start({
				// x: "-30px",
				right: "-8vw",
				y: "508px",
				rotate: -90,
				fontSize: "5vw",
				config: animationSpringConfig,
			});

			digitalBottleAnimations.current.start({
				opacity: 0,
				x: "calc(0.2% - 50%)",
				y: "10%",
				scale: initialScale,
				rotate: 0,
				immediate: true,
				config: animationSpringConfig,
			});

			return;
		}

		if (isDigitalHovered.current) {
			physicalSectionAnimations.current.start({
				width: "15%",
				config: animationSpringConfig,
			});

			physicalTitleAnimations.current.start({
				x: "-8vw",
				y: "508px",
				rotate: -90,
				fontSize: "5vw",
				config: animationSpringConfig,
			});

			physicalBottleAnimations.current.start({
				opacity: 0,
				right: 0,
				x: "50%",
				y: "10%",
				scale: initialScale,
				rotate: 0,
				immediate: true,
				config: animationSpringConfig,
			});

			digitalSectionAnimations.current.start({
				width: "85%",
				config: animationSpringConfig,
			});

			digitalTitleAnimations.current.start({
				right: "5vw",
				y: "195px",
				rotate: 0,
				fontSize: "10vw",
				config: animationSpringConfig,
			});

			digitalSubtitleAnimations.current.start({
				opacity: 1,
				right: "5vw",
				config: animationSpringConfig,
			});

			digitalBottleAnimations.current.start({
				opacity: 1,
				x: "calc(0.2% - 25%)",
				y: "10%",
				scale: selectedScale,
				rotate: -8.77,
				config: animationSpringConfig,
			});
			return;
		}

		physicalSectionAnimations.current.start({
			width: "50%",
			config: animationSpringConfig,
		});

		physicalTitleAnimations.current.start({
			y: "508px",
			x: "5vw",
			fontSize: "5vw",
			rotate: 0,
			config: animationSpringConfig,
		});

		physicalSubtitleAnimations.current.start({
			opacity: 0,
			x: "-100vw",
			config: animationSpringConfig,
		});

		physicalBottleAnimations.current.start({
			opacity: 1,
			right: 0,
			x: "50%",
			y: "10%",
			scale: initialScale,
			rotate: 0,
			config: animationSpringConfig,
		});

		digitalSectionAnimations.current.start({
			width: "50%",
			config: animationSpringConfig,
		});

		digitalTitleAnimations.current.start({
			right: "5vw",
			y: "508px",
			rotate: 0,
			fontSize: "5vw",
			config: animationSpringConfig,
		});

		digitalSubtitleAnimations.current.start({
			opacity: 0,
			right: "-100vw",
			config: animationSpringConfig,
		});

		digitalBottleAnimations.current.start({
			opacity: 1,
			x: "calc(0.2% - 50%)",
			y: "10%",
			scale: initialScale,
			rotate: 0,
			config: animationSpringConfig,
		});
	};

	const toggleDigitalAnimation = () => {
		isDigitalHovered.current = !isDigitalHovered.current;

		launchedAnimation();
	};

	const togglePhysicalAnimation = () => {
		isPhysicalHovered.current = !isPhysicalHovered.current;
		launchedAnimation();
	};

	useEffect(() => {
		const physicalSectionStyle = window.getComputedStyle(physicalSection.current!);

		initialXPhysicalImg.current = (physicalSection.current?.clientWidth ?? 0) - parseInt(physicalSectionStyle.paddingLeft ?? "0");

		launchedAnimation();
	}, []);

	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				<animated.div
					ref={physicalSection}
					className={classes["physical-section"]}
					style={enableAnimation ? physicalSectionAnimations.current.springs : {}}>
					<animated.div
						className={classes["physical-section-title-invisible"]}
						onMouseEnter={() => {
							togglePhysicalAnimation();
						}}
						onMouseLeave={() => {
							togglePhysicalAnimation();
						}}>
						<Typography type="h1" className={classes["physical-section-title"]}>
							The Physical
						</Typography>
					</animated.div>

					<AnimatedTypography
						id="physical-title"
						type="h1"
						className={classes["physical-section-title"]}
						style={enableAnimation ? physicalTitleAnimations.current.springs : {}}>
						The Physical
					</AnimatedTypography>
					<AnimatedTypography
						id="physical-subtitle"
						type="p"
						className={classes["physical-section-subtitle"]}
						style={enableAnimation ? physicalSubtitleAnimations.current.springs : {}}>
						At Raresips, we're breaking barriers between alcohol brands, collectors, and everyday consumers. When you acquire an
						alcohol-backed NFT, you're not just buying a digital asset – you're purchasing the actual bottle it represents.
						While NFTs can be traded on our marketplace, the physical bottles are stored securely in LQR House's
						temperature-controlled warehouses. Beyond trading, you can redeem the tangible alcohol bottle by burning the NFT,
						ensuring a seamless transition from digital to doorstep delivery.
					</AnimatedTypography>
					<AnimatedLazyImage
						id="physical-bottle"
						src={whistlepig1Img}
						className={classes["whistlepig-physical-bottle-img"]}
						style={enableAnimation ? physicalBottleAnimations.current.springs : {}}
						alt={"Whistlepig physical bottle"}
					/>
					<video playsInline autoPlay muted loop>
						<source src={physicalBackgroundVideo} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</animated.div>
				<animated.div
					ref={digitalSection}
					className={classes["digital-section"]}
					style={enableAnimation ? digitalSectionAnimations.current.springs : {}}>
					<animated.div
						className={classes["digital-section-title-invisible"]}
						onMouseEnter={() => {
							toggleDigitalAnimation();
						}}
						onMouseLeave={() => {
							toggleDigitalAnimation();
						}}>
						<Typography type="h1" className={classes["digital-section-title"]}>
							The Digital
						</Typography>
					</animated.div>
					<AnimatedTypography
						id="digital-title"
						type="h1"
						className={classes["digital-section-title"]}
						style={enableAnimation ? digitalTitleAnimations.current.springs : {}}>
						The Digital
					</AnimatedTypography>

					<AnimatedTypography
						id="digital-subtitle"
						type="p"
						className={classes["digital-section-subtitle"]}
						style={enableAnimation ? digitalSubtitleAnimations.current.springs : {}}>
						Explore the digital realm of Raresips' alcohol-backed NFT platform! Every NFT is digitally unique, publicly
						recorded, and easily verifiable on the blockchain, ensuring authenticity and preventing duplication. Upon purchasing
						a Raresips NFT, you receive a digital token granting proof of authenticity, verifying ownership, and enabling
						redemption of the physical bottle. These unique releases are securely stored in LQR House's state-of-the-art
						facility, while the digital token facilitates transactions through gifting or reselling. The physical goods remain
						safely stored until redeemed, ensuring a seamless transition from digital ownership to the joy of opening and
						enjoying the bottle.
					</AnimatedTypography>

					<AnimatedLazyImage
						id="digital-bottle"
						src={whistlepig2Img}
						className={classes["whistlepig-digital-bottle-img"]}
						style={enableAnimation ? digitalBottleAnimations.current.springs : {}}
						alt={"Whistlepig digital bottle"}
					/>
					<video playsInline autoPlay muted loop>
						<source src={digitalBackgroundVideo} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</animated.div>
			</div>
		</div>
	);
}
