import Font from "Configs/Font";
import Theme from "Configs/Theme";
import { AppUserEntity } from "Entities/appUser";
import ConfigStore from "Stores/ConfigStore";
import I18nStore from "Stores/I18nStore";
import ModuleStore from "Stores/ModuleStore";
import Toasts from "Stores/Toasts";
import UserStore from "Stores/UserStore";
import React from "react";
import TagManager from "react-gtm-module";
import ThemeMode, { EThemeModeType } from "../Stores/ThemeMode";
import I18n from "./Elements/I18n";
import ArrowLeftOnRectangleOutlinedIcon from "./Elements/Icons/ArrowLeftOnRectangleOutlinedIcon";
import CheckCircleSuccessOutlinedIcon from "./Elements/Icons/CheckCircleSuccessOutlinedIcon";
import MainRouter from "./MainRouter";
import Maintenance from "./Pages/Maintenance";
import { UserConnexionMethod } from "common/enums/UserConnectionMethod";
import WalletStore from "Stores/WalletStore";
import AuthConfigGuard from "./Materials/AuthConfigGuard";
import AuthConfigStore from "Stores/AuthConfigStore";
import "Configs/Theme/theme.scss";

type IProps = Record<string, never>;

type IState = {
	isOnMaintenance: boolean;
};
export default class Main extends React.Component<IProps, IState> {
	private previousUser: AppUserEntity | null = null;

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isOnMaintenance: false,
		};
		this.updateThemeMode = this.updateThemeMode.bind(this);
		this.onUpdateConfig = this.onUpdateConfig.bind(this);
	}

	public override render(): JSX.Element | null {
		if (this.state.isOnMaintenance) return <Maintenance />;
		if (ConfigStore.getInstance().config.maintenance.enabled) return <Maintenance />;
		return (
			<AuthConfigGuard>
				<MainRouter />
			</AuthConfigGuard>
		);
	}

	public override async componentDidMount() {
		ConfigStore.getInstance().onChange(this.onUpdateConfig);
		ThemeMode.getInstance().onSwitch(this.updateThemeMode);
		UserStore.getInstance().onChange(this.openConnectionToast);

		await ConfigStore.getInstance().init();

		if (ConfigStore.getInstance().config.gtm.enabled) {
			const currentProjectGtmArgs = {
				gtmId: ConfigStore.getInstance().config.gtm.id,
			};
			TagManager.initialize(currentProjectGtmArgs);
		}
	}

	private updateThemeMode(type?: EThemeModeType) {
		document.body.setAttribute("theme-mode", type ?? ThemeMode.getInstance().type);
	}

	private openConnectionToast(user: AppUserEntity | null) {
		const isManuallyConnected = WalletStore.getInstance().userConnexionMethod === UserConnexionMethod.MANUAL;

		if (!isManuallyConnected) {
			return;
		}
		if (user && (!this.previousUser || this.previousUser.id !== user.id)) {
			Toasts.getInstance().open({
				title: I18n.translate("toasts.authentication.login"),
				icon: <CheckCircleSuccessOutlinedIcon />,
			});
		}

		if (!user && this.previousUser) {
			Toasts.getInstance().open({
				title: I18n.translate("toasts.authentication.logout"),
				icon: <ArrowLeftOnRectangleOutlinedIcon />,
			});
		}

		this.previousUser = user;
	}

	private initDefaultThemeMode(config: ConfigStore["config"]) {
		const defaultTheme = (config.defaultTheme as EThemeModeType) ?? EThemeModeType.DARK;
		ThemeMode.getInstance().switch(defaultTheme);
	}

	private async onUpdateConfig(config: ConfigStore["config"]) {
		Theme.getInstance().init();
		ThemeMode.getInstance().init();

		await Promise.all([
			ModuleStore.getInstance().load(),
			I18nStore.getInstance().autoDetectAndSetLanguage(),
			Font.getInstance().init(),
		]);

		this.setState({ isOnMaintenance: config.maintenance.enabled });
		if (config.maintenance.enabled) return;
		this.initDefaultThemeMode(config);

		//lazy import AutoLoadServices
		await import("Services/AutoLoadServices").then((module) => module.default.load());

		AuthConfigStore.getInstance().setDetails({
			...AuthConfigStore.getInstance().details,
			isConfigLoaded: true,
		});
	}
}
