import AuthConfigStore, { AuthConfigDetails } from "Stores/AuthConfigStore";
import { PropsWithChildren, useEffect, useState } from "react";

type IAuthConfigGuardProps = {};

type IProps = PropsWithChildren<IAuthConfigGuardProps>;

export default function AuthConfigGuard(props: IProps) {
	const [details, setDetails] = useState(AuthConfigStore.getInstance().details);

	useEffect(() => {
		const onStatusChange = (status: AuthConfigDetails) => {
			setDetails(status);
		};
		const unsubscribe = AuthConfigStore.getInstance().onChange(onStatusChange);
		return () => {
			unsubscribe();
		};
	}, []);

	if (!details.isConfigLoaded || !details.hasJwt) {
		return null;
	}

	return props.children;
}
