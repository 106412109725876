import EventService from "Services/EventEmitter";
import configHelper from "common/helpers/config";
import DevConfig from "Configs/Config/development.json";
import StagingConfig from "Configs/Config/staging.json";
import PreprodTestnetConfig from "Configs/Config/preprod-testnet.json";
import PreprodMainnetConfig from "Configs/Config/preprod-mainnet.json";
import ProductionConfig from "Configs/Config/production.json";

const Configs = {
	development: DevConfig,
	staging: StagingConfig,
	"preprod-testnet": PreprodTestnetConfig,
	"preprod-mainnet": PreprodMainnetConfig,
	production: ProductionConfig,
};

export default class ConfigStore {
	private static instance: ConfigStore;
	private readonly event = new EventService();
	private _configPath = "/localhost";
	private _config = Configs.development;

	private constructor() {
		ConfigStore.instance = this;
	}

	public static getInstance() {
		if (!ConfigStore.instance) return new this();
		return ConfigStore.instance;
	}

	public get config() {
		return this._config;
	}

	public set config(config: ConfigStore["_config"]) {
		this._config = config;
	}

	public get configPath() {
		return this._configPath;
	}

	public set configPath(path: string) {
		this._configPath = path;
	}

	public onChange(callback: (config: ConfigStore["_config"]) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public async init() {
		try {
			this.config = Configs[(process.env["REACT_APP_ENV_NAME"] as keyof typeof Configs) || "development"];
			this.fetchHtmlMetadata();
			this.event.emit("change", this.config);
		} catch (e) {
			console.error(e);
		}
	}

	private fetchHtmlMetadata() {
		configHelper.addScriptElement(this.config.googleMapsApi.url.replace("{{apiKey}}", this.config.googleMapsApi.apiKey));
	}
}
