import AppNewsletter from "Api/Back/AppNewsletter";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import LazyImage from "Components/Elements/LazyImage";
import Typography from "Components/Elements/Typography";
import InputFieldMaterial from "Components/Materials/Inputs/InputFieldMaterial";
import configHelper from "common/helpers/config";
import { useState } from "react";
import classes from "./classes.module.scss";

type IProps = {
	listId: string;
};

export default function Newsletter(props: IProps): JSX.Element {
	const [email, setEmail] = useState("");
	const image = configHelper.getSrc("assets/images/landingPage/newsletter/newsletter.svg");
	const [badEmail, setBadEmail] = useState(true);
	const [success, setSuccess] = useState(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		await AppNewsletter.getInstance().post(email, props.listId);
		setEmail("");
		setSuccess(true);
	};

	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				<div>
					<LazyImage src={image} className={classes["icon"]} alt={"icon"}/>
				</div>
				<Typography className={classes["title"]} type="h1">
					Subscribe to Our Newsletter
				</Typography>
				<Typography className={classes["description"]} type="p">
					Join the RareSips Community
					<br />
					Never miss another release
				</Typography>
				<form className={classes["form"]} onSubmit={handleSubmit}>
					<InputFieldMaterial
						inputClassName={classes["email-input"]}
						placeholder="Email Address"
						type="email"
						name="email"
						isEmail
						onChange={(event) => {
							setEmail(event.target.value);
							if (success) setSuccess(false);
						}}
						value={email}
						onErrors={(errors) => {
							if (errors.length > 0) {
								setBadEmail(true);
								return;
							}
							setBadEmail(false);
						}}
					/>
					<Button
						color={EButtonColor.PRIMARY}
						variant={EButtonVariant.CONTAINED}
						type="submit"
						className={classes["button"]}
						disabled={badEmail}>
						Subscribe Now
					</Button>
				</form>
				{success && (
					<Typography type="p" color="success" className={classes["success"]}>
						Thank you for subscribing! We will keep you updated with the latest news and updates.
					</Typography>
				)}
			</div>
		</div>
	);
}
