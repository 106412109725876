export type FaqItem = {
	order: number;
	label: string;
	content: string;
};

export const faqQuestions: FaqItem[] = [
	{
		order: 1,
		label: "What Is RareSips?",
		content:
			"RareSips stands as a pioneering NFT platform, empowering consumers to acquire product-backed NFTs with the unique feature of exchanging their digital counterparts for the corresponding physical bottles. The digital NFT plays a vital role, serving as proof of authenticity, ownership verification, and granting entitlement to the delivery of the physical bottle. With flexible payment options, including cryptocurrency or credit card, consumers have the freedom to promptly redeem their bottles. This innovative generation of product-backed NFTs not only ensures ownership of tangible spirits but also entails secure management by LQR House, relieving customers of any storage concerns for their valuable alcohol bottles.",
	},
	{
		order: 2,
		label: 'What is a "Product Backed" NFT?',
		content:
			'A "Product Backed" NFT serves as digital proof of ownership, representing the right to claim a physical bottle. These physical bottles are meticulously stored in regulated and insured warehouses, as well as secure display venues. Simultaneously, the corresponding digital asset (NFT) can be freely collected and traded on the RareSips marketplace.<br/>Every one of our product-backed NFTs possesses digital uniqueness, is publicly recorded, and can be easily verified for authenticity on the public blockchain. This ensures that they are impossible to replicate or copy.',
	},
	{
		order: 3,
		label: "What kinds of products are available on the RareSips Marketplace?",
		content:
			"The RareSips Marketplace features a diverse selection, ranging from elusive alcohol bottles and premium allocations to unique collaborative spirits. Leveraging over two decades of relationships with key brands and distributors, our team ensures RareSips has access to a wide array of liquors not readily available elsewhere.",
	},
	{
		order: 4,
		label: "How Does RareSips Work?",
		content:
			'At RareSips, we maintain direct access to brands and top distributors worldwide. With a dedicated team scouring product catalogs and premium allocations, we mint and list product-backed NFTs on the RareSips marketplace in drops of 10 new products every few months.<br/><br/>You can buy, sell, and collect alcohol product-backed NFTs while the physical assets they represent remain securely stored in our own temperature-controlled warehouses.<br/><br/>When you redeem your NFT, your bottle of alcohol will be securely shipped to the address of your choice for you to enjoy. At this point, you will no longer have the right to transfer the digital asset.<br/><br/>Conversely, if you sell your product-backed NFT, it will be automatically transferred to the buyer, and the value of the sale will be deposited into the connected wallet. You will no longer see the NFT in your Wallet page and will be unable to "redeem" or transfer it.<br/><br/>All transactions will be documented and securely stored on the public blockchain, ensuring they are impossible to replicate or copy.',
	},
	{
		order: 5,
		label: "How can I redeem my physical bottle?",
		content:
			'If you own a RareSips NFT, you can redeem it from your Wallet page at any time. Please ensure that the alcohol product-backed NFT you wish to redeem is in your RareSips account.<br/>1. Go to your wallet.<br/>2. Click on "Redeem."<br/>3. Fill in the prompts and select your shipping address.<br/>4. Submit the details.<br/>5. Receive tracking information.<br/>6. Open and enjoy!',
	},
	{
		order: 6,
		label: "Where is my Alcohol Stored?",
		content:
			"RareSips is owned by LQR House, which boasts secure warehouses in San Diego. These warehouses are owned and operated by the LQR House management, guaranteeing that your products are diligently cared for by a highly capable and experienced team.",
	},
];
