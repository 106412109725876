import { Entity } from "common/models";
import { AppCollectionEntity } from "./appCollection";
import { AppNftEntity } from "./appNft";
import { AppSocialMediaEntity } from "./appSocialMedia";
import { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";

export enum EAppConfigHomepage {
	MARKET = "MARKET",
	LAUNCHES = "LAUNCHES",
	HOME = "HOME",
}

export interface IMarketSlider {
	title: string;
	description: string[];
	buttons: {
		label: string;
		link: string;
		variant: EButtonVariant;
		color: EButtonColor;
		direction: "right" | "down";
	}[];
	backgroundImage: string;
	showTags: boolean;
	collectionAddress?: string;
	canClickOnSlide?: boolean;
}
export interface IMarketSliders {
	market: IMarketSlider[];
	launches: IMarketSlider[];
}

export type AppConfigEntity = Entity & {
	featuredNft?: AppNftEntity | null;
	featuredCollection?: AppCollectionEntity | null;
	marketSliders?: IMarketSliders;
	appSocialMedias?: AppSocialMediaEntity[];
};
