import React, { useCallback, useEffect } from "react";
import { ReactComponent as RightArrow } from "assets/images/icons/right-arrow.svg";
import AppUser from "Api/Back/AppUser";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import Form from "Components/Elements/Form";
import { IError } from "Components/Elements/Form/Elements/BaseField";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import { AppUserEntity } from "Entities/appUser";
import UserStore from "Stores/UserStore";
import InputFieldMaterial from "../../Inputs/InputFieldMaterial";
import classes from "./classes.module.scss";
import assert from "assert";
import AppAuth from "Api/Back/AppAuth";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import ToastHelper from "common/helpers/Toast";
import { UserConnectionStatus } from "common/enums/UserConnectionStatus";
import { useModal } from "hooks/useModal";
import VerificationCodeModal, { IVerificationCodeModalProps } from "../VerificationCodeModal";

type IProps = {
	onClose: () => void;
};

type VerificationCodeModalProps = Pick<
	IVerificationCodeModalProps,
	"email" | "userName" | "firstName" | "lastName" | "displayName" | "userAddress"
>;

export default function RegisterModal(props: IProps) {
	const { showModal, hideModal } = useModal();
	const [user, setUser] = React.useState<AppUserEntity | null>(UserStore.getInstance().getUser());
	const [errorObj, setErrorObj] = React.useState<{ [key: string]: { errors: IError[] } }>({});
	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState<boolean>(false);
	const [hasError, setHasError] = React.useState<boolean>(false);
	const [hasUserAlreadyAnEmail, setHasUserAlreadyAnEmail] = React.useState<boolean>(Boolean(UserStore.getInstance().getUser()?.email));
	const header: JSX.Element = <I18n map="components.register_modal.title" />;

	const isUserObjectFilled = useCallback(() => {
		let isDisplayNameFilled: boolean;
		let isFirstNameFilled: boolean;
		let isLastNameFilled: boolean;
		let isUserNameFilled: boolean;
		let isEmailFilled: boolean;

		if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.displayName.enabled) {
			isDisplayNameFilled = true;
		} else {
			if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.displayName.props.required) {
				isDisplayNameFilled = true;
			} else {
				isDisplayNameFilled = !!user?.displayName;
			}
		}

		if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.firstName.enabled) {
			isFirstNameFilled = true;
		} else {
			if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.firstName.props.required) {
				isFirstNameFilled = true;
			} else {
				isFirstNameFilled = !!user?.firstName;
			}
		}
		if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.lastName.enabled) {
			isLastNameFilled = true;
		} else {
			if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.lastName.props.required) {
				isLastNameFilled = true;
			} else {
				isLastNameFilled = !!user?.lastName;
			}
		}
		if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.userName.enabled) {
			isUserNameFilled = true;
		} else {
			if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.userName.props.required) {
				isUserNameFilled = true;
			} else {
				isUserNameFilled = !!user?.userName;
			}
		}

		if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.email.enabled) {
			isEmailFilled = true;
		} else {
			if (!IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.email.props.required) {
				isEmailFilled = true;
			} else {
				isEmailFilled = !!user?.email;
			}
		}

		return isDisplayNameFilled && isFirstNameFilled && isLastNameFilled && isUserNameFilled && isEmailFilled;
	}, [user]);

	const setSubmitButtonDisabledState = useCallback(() => {
		setIsSubmitButtonDisabled(!isUserObjectFilled());
	}, [isUserObjectFilled]);

	const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setUser((prevState) => {
			const previousState = prevState ?? UserStore.getInstance().getUser();
			if (!previousState) return prevState;
			return {
				...previousState,
				[name]: value,
			};
		});
	}, []);

	const updateUser = useCallback((user: AppUserEntity | null) => {
		setUser(user);
		setHasUserAlreadyAnEmail(Boolean(user?.email));
	}, []);

	const setDisplayRegistrationModalToFalse = useCallback(async () => {
		try {
			const userAddress = user?.appWallet?.userAddress;
			if (!userAddress) throw new Error("userAddress NotFound");
			await AppUser.getInstance().update(userAddress, {
				displayRegistrationModal: false,
			});
		} catch (e) {
			console.error("error", e);
			ToastHelper.createAnErrorOccuredToast();
		}
	}, [user]);

	const closeModal = useCallback(async () => {
		if (user?.displayRegistrationModal) {
			await setDisplayRegistrationModalToFalse();
		}
		props.onClose();
	}, [props, setDisplayRegistrationModalToFalse, user]);

	const sendVerificationCodeAndOpenModal = useCallback(
		async (verificationCodeModalProps: VerificationCodeModalProps) => {
			try {
				await AppAuth.getInstance().sendEmailPin({
					email: verificationCodeModalProps.email,
				});
				await closeModal();
				showModal(<VerificationCodeModal {...verificationCodeModalProps} onClose={hideModal} />);
			} catch (e) {
				console.error("error", e);
			}
		},
		[closeModal, showModal, hideModal],
	);

	const onSubmit = useCallback(
		async (_: React.FormEvent<HTMLFormElement> | null, values: { [key: string]: string }) => {
			const userAddress = user?.appWallet?.userAddress;
			if (!userAddress) throw new Error("userAddress NotFound");

			try {
				if (hasUserAlreadyAnEmail || !values["email"]) {
					await closeModal();
					const updatedUser = await AppUser.getInstance().update(userAddress, {
						displayName: values["displayName"],
						firstName: values["firstName"],
						lastName: values["lastName"],
						userName: values["userName"],
					});
					UserStore.getInstance().setUser(updatedUser, UserConnectionStatus.CONNECTED);
					return;
				}

				const verificationCodeModalProps: VerificationCodeModalProps = {
					email: values["email"],
					userAddress,
					userName: values["userName"],
					firstName: values["firstName"],
					lastName: values["lastName"],
					displayName: values["displayName"],
				};
				await sendVerificationCodeAndOpenModal(verificationCodeModalProps);
			} catch (e) {
				console.error("error", e);
				ToastHelper.createAnErrorOccuredToast();
			}
		},
		[hasUserAlreadyAnEmail, user, closeModal, sendVerificationCodeAndOpenModal],
	);

	const onErrors = useCallback(
		(key: string, errors: IError[]) => {
			errorObj[key] = { errors };
			const hasErrors = Object.entries(errorObj).some(([_, value]) => value.errors.length > 0);
			setHasError(hasErrors);
			setErrorObj(errorObj);
		},
		[errorObj],
	);

	useEffect(() => UserStore.getInstance().onChange(updateUser), [updateUser]);
	useEffect(() => setSubmitButtonDisabledState(), [setSubmitButtonDisabledState]);

	return (
		<Modal header={header} closeBtn={true} isOpen onClose={closeModal}>
			<div className={classes["root"]}>
				<Form onSubmit={onSubmit}>
					<div className={classes["input-container"]}>
						<IsModuleEnabled from={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.userName}>
							<InputFieldMaterial
								name="userName"
								defaultValue={user?.userName ?? ""}
								label={I18n.translate("components.register_modal.form.username")}
								minFieldLength={{ args: [3] }}
								maxFieldLength={{ args: [20] }}
								isPseudo
								noSpaceInString
								isUniqueUsername
								required={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.userName.props.required}
								onChange={onChange}
								onErrors={(errors) => onErrors("userName", errors)}
							/>
						</IsModuleEnabled>

						<IsModuleEnabled from={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.displayName}>
							<InputFieldMaterial
								name="displayName"
								defaultValue={user?.displayName ?? ""}
								label={I18n.translate("components.register_modal.form.display_name")}
								required={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.displayName.props.required}
								onErrors={(errors) => onErrors("displayName", errors)}
								maxFieldLength={{ args: [20] }}
								onChange={onChange}
							/>
						</IsModuleEnabled>

						<IsModuleEnabled from={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.firstName}>
							<InputFieldMaterial
								name="firstName"
								defaultValue={user?.firstName ?? ""}
								label={I18n.translate("pages.settings.subPages.general.form.first_name")}
								required={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.firstName.props.required}
								onErrors={(errors) => onErrors("firstName", errors)}
								maxFieldLength={{ args: [20] }}
								onChange={onChange}
							/>
						</IsModuleEnabled>

						<IsModuleEnabled from={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.lastName}>
							<InputFieldMaterial
								name="lastName"
								defaultValue={user?.lastName ?? ""}
								label={I18n.translate("pages.settings.subPages.general.form.last_name")}
								required={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.lastName.props.required}
								onErrors={(errors) => onErrors("lastName", errors)}
								maxFieldLength={{ args: [20] }}
								onChange={onChange}
							/>
						</IsModuleEnabled>

						{!hasUserAlreadyAnEmail && (
							<IsModuleEnabled from={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.email}>
								<InputFieldMaterial
									name="email"
									defaultValue={user?.email ?? ""}
									label={I18n.translate("pages.settings.subPages.general.form.email")}
									required={IsModuleEnabled.get().Modals.props.RegisterModal.props.fields.email.props.required}
									isEmail
									isUniqueEmail
									onChange={onChange}
									onErrors={(errors) => onErrors("email", errors)}
								/>
							</IsModuleEnabled>
						)}
					</div>

					<div className={classes["button-container"]}>
						<Button
							variant={EButtonVariant.CONTAINED}
							color={EButtonColor.PRIMARY}
							type="submit"
							endIcon={<RightArrow />}
							disabled={(() => {
								return isSubmitButtonDisabled || hasError;
							})()}>
							<I18n map="components.register_modal.create_account" />
						</Button>
						<Button variant={EButtonVariant.TEXT} onClick={closeModal}>
							<I18n map="components.register_modal.complete_later" />
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	);
}
