import configHelper from "common/helpers/config";
import fonts from "Configs/Theme/fonts.json";

export default class Font {
	private static ctx: Font;
	private isFontLoaded: boolean = false;
	private static readonly fontFileName = "fonts.json";
	private static readonly baseUrl = "assets/fonts";

	private constructor() {
		Font.ctx = this;
	}

	public static getInstance() {
		if (!Font.ctx) return new this();
		return Font.ctx;
	}

	public async init() {
		if (this.isFontLoaded) return;
		try {
			await this.loadFont();
		} catch (e) {
			console.error(e);
		}
		this.isFontLoaded = true;
	}

	private async loadFont() {
		const fontsConfig = fonts;
		fontsConfig.forEach(async (font: any) => {
			const url = configHelper.getSrc(`${Font.baseUrl}${font["src"]}`);
			const newFont = new FontFace(font["font-family"], `url('${url}')`, {
				style: font["font-style"],
				weight: font["font-weight"],
			});
			// wait for font to be loaded
			await newFont.load();
			// add font to document
			document.fonts.add(newFont);
		});
	}
}
