import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import LazyImage from "Components/Elements/LazyImage";
import Typography from "Components/Elements/Typography";
import TokenPrice from "Components/Materials/TokenPrice";
import { isOrder } from "Entities/appLastSale";
import { AppNftEntity } from "Entities/appNft";
import { OrderType } from "Entities/appOrder";
import BigNumber from "Services/BigNumber";
import classNames from "classnames";
import configHelper from "common/helpers/config";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./classes.module.scss";
import { ENewReleasesFilterOptionsValue, newReleasesFilterOptions } from "./entity";
import AppNft from "Api/Back/AppNft";

const arrow = configHelper.getSrc("assets/images/landingPage/newReleases/icons/arrow.svg");

type IProps = {
	tequilaCollectionAddress: string;
	whiskeyCollectionAddress: string;
	scotchCollectionAddress: string;
	bourbonCollectionAddress: string;
	ownerAddress: string;
};

export default function NewReleases(props: IProps): JSX.Element {
	const [selectedValue, setSelectedValue] = useState(ENewReleasesFilterOptionsValue.TEQUILA);
	const onFilterOptionClick = useCallback((value: ENewReleasesFilterOptionsValue) => setSelectedValue(value), []);
	const [nftsToDisplay, setNftsToDisplay] = useState<AppNftEntity[]>([]);
	const [nftCollections, setNftCollections] = useState<Record<ENewReleasesFilterOptionsValue, AppNftEntity[]>>({
		[ENewReleasesFilterOptionsValue.TEQUILA]: [],
		[ENewReleasesFilterOptionsValue.WHISKEY]: [],
		[ENewReleasesFilterOptionsValue.SCOTCH]: [],
		[ENewReleasesFilterOptionsValue.BOURBON]: [],
	});

	const nftsToDisplayLength = useMemo(() => {
		const nftsWithOrder = nftsToDisplay.filter((nft) => {
			const lastSale = nft.appLastSale;
			if (!lastSale) return false;
			const isOrderSale = isOrder(lastSale) && lastSale.appOrder.type === OrderType.SELL ? lastSale.appOrder : undefined;
			return isOrderSale;
		});
		return nftsWithOrder.length;
	}, [nftsToDisplay]);

	const fetchNfts = useCallback(
		async (collectionAddress: string) => {
			return AppNft.getInstance().getNfts({
				userAddresses: [props.ownerAddress],
				collections: [{ address: collectionAddress }],
			});
		},
		[props.ownerAddress],
	);

	const fetchAndSetNfts = useCallback(async () => {
		try {
			const tequilaNfts = await fetchNfts(props.tequilaCollectionAddress);
			const whiskeyNfts = await fetchNfts(props.whiskeyCollectionAddress);
			const scotchNfts = await fetchNfts(props.scotchCollectionAddress);
			const bourbonNfts = await fetchNfts(props.bourbonCollectionAddress);

			setNftCollections({
				[ENewReleasesFilterOptionsValue.TEQUILA]: tequilaNfts.data,
				[ENewReleasesFilterOptionsValue.WHISKEY]: whiskeyNfts.data,
				[ENewReleasesFilterOptionsValue.SCOTCH]: scotchNfts.data,
				[ENewReleasesFilterOptionsValue.BOURBON]: bourbonNfts.data,
			});
		} catch (error) {
			console.error("Error while fetching NFTs", error);
		}
	}, [
		props.bourbonCollectionAddress,
		props.scotchCollectionAddress,
		props.tequilaCollectionAddress,
		props.whiskeyCollectionAddress,
		fetchNfts,
	]);

	useEffect(() => {
		fetchAndSetNfts();
	}, [fetchAndSetNfts]);

	useEffect(() => setNftsToDisplay(nftCollections[selectedValue]), [selectedValue, nftCollections]);

	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				<Typography type="h1" className={classes["title"]}>
					New Releases
				</Typography>
				<div className={classes["bottles-type-container"]}>
					{newReleasesFilterOptions.map((option) => (
						<div
							key={option.value}
							className={classes["bottle-type-wrapper"]}
							data-selected={option.value === selectedValue}
							onClick={() => onFilterOptionClick(option.value)}>
							<div
								className={classNames(classes["bottle-type-icon"], {
									[classes["bottle-type-icon-selected"]!]: option.value === selectedValue,
								})}>
								<option.img />
							</div>
							<Typography
								type="span"
								className={[
									classes["bottle-type-name"],
									option.value === selectedValue ? classes["bottle-type-selected"] : "",
								].join(" ")}>
								{option.label}
							</Typography>
						</div>
					))}
				</div>
				<div
					className={classNames(classes["bottles-container"], {
						[classes["align-center"]!]: nftsToDisplayLength < 4,
					})}>
					{nftsToDisplay.map((nft) => {
						const collectionAddress = nft.appCollection.appContract?.address;
						if (!collectionAddress) return <></>;
						const linkToNft = IsModuleEnabled.get()
							.pages.NFT.props.path.replace(":collectionAddress", collectionAddress)
							.replace(":tokenId", nft.tokenId.toString());
						const lastSale = nft.appLastSale;
						if (!lastSale) return <></>;
						const isOrderSale = isOrder(lastSale) && lastSale.appOrder.type === OrderType.SELL ? lastSale.appOrder : undefined;
						if (!isOrderSale) return <></>;

						return (
							<div className={classes["bottle-wrapper"]}>
								<Link to={linkToNft}>
									<div className={classes["image-container"]}>
										<LazyImage src={nft.image?.url} className={classes["bottle-img"]} alt={nft.name}/>
									</div>
									<Typography type="span" className={classes["bottle-name"]}>
										{nft.name}
									</Typography>
									<TokenPrice
										token={isOrderSale.appTokenSupport}
										price={BigNumber.from(isOrderSale.tokenSupportAmount)}
										conversionConfig={{ direction: "row", size: "small" }}
										typo={{ color: "primary" }}
										className={classes["bottle-price"]}
									/>
									<Button
										variant={EButtonVariant.OUTLINED}
										color={EButtonColor.PRIMARY}
										className={classes["bottle-buy-button"]}>
										Buy me now
									</Button>
								</Link>
							</div>
						);
					})}
					{nftsToDisplayLength === 0 && (
						<div className={classes["sold-out-container"]}>
							<Typography type="p" className={classes["sold-out"]}>
								Sold out!
							</Typography>
						</div>
					)}
				</div>
				<Link to={IsModuleEnabled.get().pages.Market.props.path}>
					<Button
						variant={EButtonVariant.TEXT}
						color={EButtonColor.PRIMARY}
						className={classes["view-all-nfts-button"]}
						endIcon={<LazyImage src={arrow} className={classes["icon"]} alt={"arrow"}/>}>
						View all NFTs
					</Button>
				</Link>
			</div>
		</div>
	);
}
