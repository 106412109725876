import Button, { EButtonVariant } from "Components/Elements/Buttons/Button";
import configHelper from "common/helpers/config";
import classes from "./classes.module.scss";
import IsModuleEnabled from "Components/Elements/IsModuleEnabled";
import { Link } from "react-router-dom";

export default function Banner(): JSX.Element {
	const bannerBackgroundImage = configHelper.getSrc("assets/images/landingPage/banner/banner.webp");

	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				<div className={classes["banner"]} style={{ backgroundImage: `url(${bannerBackgroundImage})` }}>
					<div className={classes["banner-header"]}>Start Your Exclusive Collection:</div>
					<div className={classes["banner-title"]}>
						Rare Alcohol Bottles
						<br />– No Storage Worries!
					</div>
					<Link to={IsModuleEnabled.get().pages.Market.props.path}>
						<Button variant={EButtonVariant.CONTAINED} className={classes["banner-button"]}>
							GET STARTED
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
}
