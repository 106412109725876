import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import LazyImage from "Components/Elements/LazyImage";
import configHelper from "common/helpers/config";
import classes from "./classes.module.scss";
import UniqueSellingPoint from "../../Common/UniqueSellingPoint";
import { Link } from "react-router-dom";

export default function HowItWorks(): JSX.Element {
	const arrow = configHelper.getSrc("assets/images/landingPage/howItWorks/icons/arrow.svg");

	return (
		<div className={classes["root"]}>
			<UniqueSellingPoint />
			<Link to={"/how-it-works"}>
				<Button
					variant={EButtonVariant.TEXT}
					color={EButtonColor.PRIMARY}
					className={classes["button-view-more"]}
					endIcon={<LazyImage src={arrow} className={classes["icon"]} alt={"icon"}/>}>
					See How It Works
				</Button>
			</Link>
		</div>
	);
}
