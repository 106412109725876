import configHelper from "common/helpers/config";
import classes from "./classes.module.scss";
import LazyImage from "Components/Elements/LazyImage";

export default function HowItWorks(): JSX.Element {
	const accessImage = configHelper.getSrc("assets/images/landingPage/howItWorks/icons/access.svg");
	const authenticityImage = configHelper.getSrc("assets/images/landingPage/howItWorks/icons/authenticity.svg");
	const blockchainImage = configHelper.getSrc("assets/images/landingPage/howItWorks/icons/blockchain.svg");
	const conciergeImage = configHelper.getSrc("assets/images/landingPage/howItWorks/icons/concierge.svg");
	const expertiseImage = configHelper.getSrc("assets/images/landingPage/howItWorks/icons/expertise.svg");
	const resellImage = configHelper.getSrc("assets/images/landingPage/howItWorks/icons/resell.svg");

	const elements = [
		{
			title: "Premium Allocations",
			description:
				"With two decades' worth of established relationships with producers and distributors in the alcohol industry, LQR House has exclusive access to premium allocations that are not readily available. Furthermore, our strategic partnerships with distinctive alcohol brands empower us to present unparalleled products that are rare releases made in limited quantities.",
			image: accessImage,
			alt: "access",
		},
		{
			title: "RareSips is Founded on Collaboration",
			description:
				"The LQR Team behind RareSips consistently purchases directly from reputable sources, emphasizing the company's commitment to partnerships and collaborations. This approach guarantees that every product featured on the marketplace is 100% authentic and has been handled with the utmost care throughout its journey.",
			image: blockchainImage,
			alt: "blockchain",
		},

		{
			title: "Extensive Expertise",
			description:
				"The team at LQR House brings decades of experience in evaluating the value and quality of fine and rare bottles, complemented by a deep understanding of storage, logistics, and technology.",
			image: expertiseImage,
			alt: "expertise",
		},
		{
			title: "Store and Resell",
			description:
				"LQR House offers state-of-the-art storage solutions to ensure the safety of your “physical” NFT, managed by seasoned professionals. The Raresips Marketplace provides straightforward reselling and gifting options that are user-friendly, allowing owners to effortlessly manage their collections.",
			image: resellImage,
			alt: "resell",
		},
		{
			title: "Premium Condition & 100% Authenticity",
			description:
				"The digital NFT is supported by a 100% authentic physical bottle of alcohol, securely stored with all necessary documents to ensure it remains in the most optimal state. The Raresips platform is founded on transparent blockchain technology, ensuring both authenticity and condition guarantees.",
			image: authenticityImage,
			alt: "authenticity",
		},
		{
			title: "At Your Service",
			description:
				"The LQR House team is dedicated to assisting owners in sourcing, sharing, selling, and shipping their bottles worldwide. We are readily available via email or phone to discuss your desires and requirements.",
			image: conciergeImage,
			alt: "concierge",
		},
	];
	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				{elements.map((element) => (
					<div className={classes["wrapper"]} key={element.title}>
						<LazyImage src={element.image} className={classes["icon"]} alt={element.alt}/>
						<div className={classes["title"]}>{element.title}</div>
						<div className={classes["description"]}>{element.description}</div>
					</div>
				))}
			</div>
		</div>
	);
}
