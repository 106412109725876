import BaseApiService from "Api/BaseApiService";
import ConfigStore from "Stores/ConfigStore";

export type PostNewsletterResponse = {
	profile?: {
		id: string;
	};
};

export default class AppNewsletter extends BaseApiService {
	private static instance: AppNewsletter;

	private constructor() {
		super();
		AppNewsletter.instance = this;
	}

	private get baseUrl() {
		return this.backUrl.concat("/").concat(ConfigStore.getInstance().config.app).concat("/app-newsletter");
	}

	public static getInstance(reset?: boolean) {
		if (!AppNewsletter.instance || reset) return new this();
		return AppNewsletter.instance;
	}

	public async post(email: string, listId: string): Promise<PostNewsletterResponse> {
		const url = new URL(this.baseUrl.concat(`/lists/${listId}/subscribe/${email}`));

		try {
			const response = await this.postRequest<PostNewsletterResponse>(url);

			return response;
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
