import FAQ from "../FAQ";
import Newsletter from "../Newsletter";
import PhysicalDigital from "../PhysicalDigital";
import Banner from "./Banner";
import HowItWorks from "./HowItWorks";

import NewReleases from "./NewReleases";

import DevModule from "Configs/Module/development.json";
import Welcome from "./Welcome";

type IProps = typeof DevModule.modules.pages.Home.props;

export default function Homepage(props: IProps) {
	return (
		<div>
			<Welcome />
			<PhysicalDigital />

			<NewReleases {...props.newReleases.props} />
			<Banner />
			<HowItWorks />
			<FAQ />
			{props.newsletter.enabled && <Newsletter {...props.newsletter.props} />}
		</div>
	);
}
