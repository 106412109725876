import AppConfig from "Api/Back/AppConfig";
import { AppConfigEntity } from "Entities/appConfig";
import EventService from "Services/EventEmitter";
import ProductionConfig from "Configs/AppConfig/production.json";
import PreprodTestnetConfig from "Configs/AppConfig/preprod-testnet.json";
import PreprodMainnetConfig from "Configs/AppConfig/preprod-mainnet.json";
import StagingConfig from "Configs/AppConfig/staging.json";
import DevelopmentConfig from "Configs/AppConfig/development.json";

const AppConfigs = {
	development: DevelopmentConfig,
	staging: StagingConfig,
	"preprod-testnet": PreprodTestnetConfig,
	"preprod-mainnet": PreprodMainnetConfig,
	production: ProductionConfig,
};

export default class AppConfigStore {
	private static instance: AppConfigStore;
	private readonly event = new EventService();
	private readonly appConfig = AppConfig.getInstance();

	private config: AppConfigEntity | null = null;

	private constructor() {
		AppConfigStore.instance = this;
	}

	public static getInstance() {
		if (!AppConfigStore.instance) return new this();
		return AppConfigStore.instance;
	}

	public getConfig() {
		return this.config;
	}

	public setConfig(config: AppConfigEntity | null) {
		this.config = config;
		this.event.emit("change", config);
	}

	public async fetchConfig() {
		const config = AppConfigs[(process.env["REACT_APP_ENV_NAME"] as keyof typeof AppConfigs) || "development"] as AppConfigEntity;
		this.setConfig(config);
		
	}

	public onChange(callback: (config: AppConfigEntity | null) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}
}
