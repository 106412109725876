import classnames from "classnames";
import classes from "./classes.module.scss";
import Typography from "Components/Elements/Typography";
import I18n from "Components/Elements/I18n";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { FaqItem } from "../questions";

type IProps = {
	questions: FaqItem[];
};

export default function Dropdown(props: IProps) {
	const [openedItem, setOpenedItem] = useState<number | null>(null);

	function toggleFAQItem(key: number): void {
		setOpenedItem(openedItem === key ? null : key);
	}

	return (
		<div className={classes["items-container"]}>
			{props.questions.map((item) => (
				<div
					key={item.order}
					className={classnames(classes["item"], {
						[classes["open"]!]: openedItem === item.order,
					})}
					onClick={() => toggleFAQItem(item.order)}>
					<div className={classes["item-header"]}>
						<div className={classes["question-and-icon"]}>
							<Typography type="p" className={classes["question"]}>
								<I18n map={item.label} />
							</Typography>
							<div
								className={classnames(classes["icon-container"], {
									[classes["open"]!]: openedItem === item.order,
								})}>
								<ChevronDownIcon className={classes["icon"]} />
							</div>
						</div>
					</div>
					<div
						className={classnames(classes["item-response"], {
							[classes["open"]!]: openedItem === item.order,
						})}>
						<Typography type="p">
							<span dangerouslySetInnerHTML={{ __html: I18n.translate(`${item.content}`) }} />
						</Typography>
					</div>
				</div>
			))}
		</div>
	);
}
