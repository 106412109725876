import EventService from "Services/EventEmitter";
import JwtStore from "./JwtStore";


export type AuthConfigDetails = {
	hasJwt: boolean;
	isConfigLoaded: boolean;
};

export default class AuthConfigStore {
	private static instance: AuthConfigStore;
	private readonly event = new EventService();
	private _details: AuthConfigDetails = {
		hasJwt: Boolean(JwtStore.getInstance().getPair()),
		isConfigLoaded: false,
	};

	private constructor() {
		AuthConfigStore.instance = this;
	}

	public static getInstance() {
		if (!AuthConfigStore.instance) return new this();
		return AuthConfigStore.instance;
	}

	public get details() {
		return this._details;
	}

	public setDetails(details: AuthConfigDetails) {
		this._details = details;
		this.event.emit("change", details);
	}

	public onChange(callback: (status: AuthConfigDetails) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}
}
